  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-14-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Avignon</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Pourquoi réaliser une expertise fissure ? </h1>

<p>Votre ouvrage fissure ? vos murs, plafonds ou carrelage fissurent ? </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Avignon" />
</div>

<p>Ces fissures sont-elles conséquentes ? </p>
<p>Quelles sont les causes et les origines de ces fissures ? </p>
<p>Quels moyens de réparation existent sur le long terme?</p>

<p>L’expertise fissure vous permet de déterminer quelles sont les causes et origines de ces désordres. Votre Expert vous orientera sur les travaux à réaliser et le coût estimatif de ces travaux afin de pallier de façon pérenne ces pathologies.</p>

<h2>Cabinet RV Expertises, spécialiste des pathologies de la construction </h2>

<p>Nos experts techniques bâtiment et pathologies de la construction interviennent pour vos demandes d’expertise sur la région d’Avignon.</p>

<p>Besoin d’un avis technique ? d’être accompagné pour une contre-expertise ?</p>

<p>N’hésitez pas à nous contacter afin d’être accompagné par un Expert technique bâtiment.</p>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert fissure</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-avignon/' className='active'>Expert fissure Avignon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details